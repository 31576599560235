var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "见点奖", name: "1" } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("见点奖")])
                  ]),
                  _c("el-tag", [_vm._v("如果填入金额<0,奖金按百分比计算")]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.bonus1 }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.layer))]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "金额", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.bonus,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bonus", $$v)
                                    },
                                    expression: "scope.row.bonus"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "是否是玉兔", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "是否是玉兔" },
                                    model: {
                                      value: scope.row.grade3,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "grade3", $$v)
                                      },
                                      expression: "scope.row.grade3"
                                    }
                                  },
                                  [
                                    _c("el-option", {
                                      key: "0",
                                      attrs: { label: "非玉兔", value: "0" }
                                    }),
                                    _c("el-option", {
                                      key: "1",
                                      attrs: { label: "玉兔", value: "1" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickDeleteBonus1(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "15px 0", height: "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-left": "20px"
                          },
                          attrs: { size: "mini", type: "success" },
                          on: { click: _vm.clickSave1 }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.clickAddBonus1 }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预备季", name: "2" } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("预备季")])
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.bonus3,
                        "label-width": "150px",
                        size: "small"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐人数" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            model: {
                              value: _vm.bonus3.paramUserNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bonus3,
                                  "paramUserNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "bonus3.paramUserNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "份额分割数" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            model: {
                              value: _vm.bonus3.paramsShareNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bonus3,
                                  "paramsShareNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "bonus3.paramsShareNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "份额百分比1" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              on: {
                                input: function(value) {
                                  return _vm.handlePriceInput(
                                    value,
                                    _vm.bonus3,
                                    "paramsShareRate1"
                                  )
                                }
                              },
                              model: {
                                value: _vm.bonus3.paramsShareRate1,
                                callback: function($$v) {
                                  _vm.$set(_vm.bonus3, "paramsShareRate1", $$v)
                                },
                                expression: "bonus3.paramsShareRate1"
                              }
                            }),
                            _c(
                              "el-tag",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("<=分割数")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "份额百分比2" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              on: {
                                input: function(value) {
                                  return _vm.handlePriceInput(
                                    value,
                                    _vm.bonus3,
                                    "paramsShareRate2"
                                  )
                                }
                              },
                              model: {
                                value: _vm.bonus3.paramsShareRate2,
                                callback: function($$v) {
                                  _vm.$set(_vm.bonus3, "paramsShareRate2", $$v)
                                },
                                expression: "bonus3.paramsShareRate2"
                              }
                            }),
                            _c(
                              "el-tag",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v(">分割数")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "份额百分比2(限购计算)" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                on: {
                                  input: function(value) {
                                    return _vm.handlePriceInput(
                                      value,
                                      _vm.bonus3,
                                      "paramsShareRate3"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.bonus3.paramsShareRate3,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bonus3,
                                      "paramsShareRate3",
                                      $$v
                                    )
                                  },
                                  expression: "bonus3.paramsShareRate3"
                                }
                              }),
                              _c(
                                "el-tag",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(">分割数")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "每份预估值" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              on: {
                                input: function(value) {
                                  return _vm.handlePriceInput(
                                    value,
                                    _vm.bonus3,
                                    "paramsSharePv"
                                  )
                                }
                              },
                              model: {
                                value: _vm.bonus3.paramsSharePv,
                                callback: function($$v) {
                                  _vm.$set(_vm.bonus3, "paramsSharePv", $$v)
                                },
                                expression: "bonus3.paramsSharePv"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每日限购(自动计算)" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { value: _vm.bonus3.paramsLimitNumAuto }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每日限购(实际值)" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.bonus3.paramsLimitNum,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bonus3,
                                      "paramsLimitNum",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "bonus3.paramsLimitNum"
                                }
                              }),
                              _c(
                                "el-tag",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("每日自动计算，可手动修改")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "春季限购时间" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "value-format": "HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围"
                            },
                            model: {
                              value: _vm.bonus3.paramsGradeBuyTime0,
                              callback: function($$v) {
                                _vm.$set(_vm.bonus3, "paramsGradeBuyTime0", $$v)
                              },
                              expression: "bonus3.paramsGradeBuyTime0"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "夏季限购时间" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "value-format": "HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围"
                            },
                            model: {
                              value: _vm.bonus3.paramsGradeBuyTime1,
                              callback: function($$v) {
                                _vm.$set(_vm.bonus3, "paramsGradeBuyTime1", $$v)
                              },
                              expression: "bonus3.paramsGradeBuyTime1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "秋季限购时间" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "value-format": "HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围"
                            },
                            model: {
                              value: _vm.bonus3.paramsGradeBuyTime2,
                              callback: function($$v) {
                                _vm.$set(_vm.bonus3, "paramsGradeBuyTime2", $$v)
                              },
                              expression: "bonus3.paramsGradeBuyTime2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "冬季限购时间" } },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              "is-range": "",
                              "value-format": "HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围"
                            },
                            model: {
                              value: _vm.bonus3.paramsGradeBuyTime3,
                              callback: function($$v) {
                                _vm.$set(_vm.bonus3, "paramsGradeBuyTime3", $$v)
                              },
                              expression: "bonus3.paramsGradeBuyTime3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "15px 0", height: "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-left": "20px"
                          },
                          attrs: { size: "mini", type: "success" },
                          on: { click: _vm.clickSave2 }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预备季直推", name: "3" } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("预备季直推")])
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.bonus4 }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "层", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.layer))]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "百分比", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  on: {
                                    input: function(value) {
                                      return _vm.handlePriceInput(
                                        value,
                                        scope.row,
                                        "bonus"
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.bonus,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bonus", $$v)
                                    },
                                    expression: "scope.row.bonus"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickDeleteBonus4(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "15px 0", height: "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-left": "20px"
                          },
                          attrs: { size: "mini", type: "success" },
                          on: { click: _vm.clickSave4 }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.clickAddBonus4 }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "分红", name: "4" } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("分红")])
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.bonus2 }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.layer))]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "金额", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.bonus,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "bonus", $$v)
                                    },
                                    expression: "scope.row.bonus"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickDeleteBonus1(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "15px 0", height: "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-left": "20px"
                          },
                          attrs: { size: "mini", type: "success" },
                          on: { click: _vm.clickAddBonus1 }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.clickAddBonus1 }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "抽奖", name: "5" } },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _c("span", [_vm._v("抽奖")]),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-left": "20px"
                            },
                            attrs: { size: "mini", type: "success" },
                            on: { click: _vm.clickGiftMake }
                          },
                          [_vm._v("奖品生成")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }