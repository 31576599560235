import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-float";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { configBonusList, configBonusHandle, refreshGift } from '@/api/apis';
export default {
  name: 'index',
  data: function data() {
    return {
      activeName: '1',
      bonus1: [],
      bonus2: [],
      bonus3: {
        paramUserNum: 2,
        paramsShareNum: 3,
        paramsShareRate1: 0.1,
        paramsShareRate2: 0.5,
        paramsShareRate3: 0.5,
        paramsSharePv: 10,
        paramsLimitNum: 1000,
        paramsLimitNumAuto: 1000,
        paramsGradeBuyTime0: [],
        paramsGradeBuyTime1: [],
        paramsGradeBuyTime2: [],
        paramsGradeBuyTime3: []
      },
      bonus3Ex: {
        balanceExpectNum: 0,
        lastExpectNum: 0,
        status: 0
      },
      bonus4: [],
      bonus5: [],
      bonus5Ex: {
        balanceExpectNum: 0,
        lastExpectNum: 0
      },
      bonus3Status: 0,
      bonus5Status: 0,
      id1: '',
      id2: '',
      id3: '',
      id4: '',
      id5: ''
    };
  },
  mounted: function mounted() {
    var _this = this;

    configBonusList().then(function _callee(res) {
      var data, i;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              data = res.rows;

              for (i in data) {
                if (data[i].tag == 0) {
                  _this.bonus1 = JSON.parse(data[i].conditions);
                  _this.id1 = data[i].id;
                } else if (data[i].tag == 1) {
                  _this.bonus2 = JSON.parse(data[i].conditions);
                  _this.id2 = data[i].id;
                } else if (data[i].tag == 3) {
                  _this.bonus3 = JSON.parse(data[i].conditions);
                  _this.id3 = data[i].id;
                  _this.bonus3Ex = JSON.parse(data[i].exa_cond);
                  _this.bonus3Status = data[i].status;
                } else if (data[i].tag == 4) {
                  _this.bonus4 = JSON.parse(data[i].conditions);
                  _this.id4 = data[i].id;
                } else if (data[i].tag == 5) {
                  _this.bonus5 = JSON.parse(data[i].conditions);
                  _this.id5 = data[i].id;
                  _this.bonus5Ex = JSON.parse(data[i].exa_cond);
                  _this.bonus5Status = data[i].status;
                }
              }

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    handlePriceInput: function handlePriceInput(value, data, key) {
      // 允许的格式为数字并最多保留两位小数
      var formattedValue = value.replace(/[^\d.]/g, '') // 去除非数字和小数点的字符
      .replace(/^0+(\d)/, '$1') // 去除多余的前导零，但保留 "0." 形式
      .replace(/^\./, '0.') // 如果第一个字符是 "."，则改为 "0."
      .replace(/(\.\d{3})\d+/, '$1'); // 限制小数点后最多两位
      // 保证只能有一个小数点

      var dotIndex = formattedValue.indexOf('.');

      if (dotIndex !== -1) {
        formattedValue = formattedValue.slice(0, dotIndex + 1) + formattedValue.slice(dotIndex + 1).replace(/\./g, '');
      }

      data[key] = formattedValue;
    },
    clickSave1: function clickSave1() {
      var _this2 = this;

      console.log("this.bonus1", this.bonus1);
      configBonusHandle({
        id: this.id1,
        conditions: JSON.stringify(this.bonus1)
      }).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$alert('保存成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    clickSave2: function clickSave2() {
      var _this3 = this;

      console.log('this.id3', this.id3, this.bonus3);
      configBonusHandle({
        id: this.id3,
        conditions: JSON.stringify({
          paramUserNum: parseInt(this.bonus3.paramUserNum),
          paramsShareNum: parseInt(this.bonus3.paramsShareNum),
          paramsShareRate1: parseFloat(this.bonus3.paramsShareRate1),
          paramsShareRate2: parseFloat(this.bonus3.paramsShareRate2),
          paramsShareRate3: parseFloat(this.bonus3.paramsShareRate3),
          paramsSharePv: parseFloat(this.bonus3.paramsSharePv),
          paramsLimitNum: parseInt(this.bonus3.paramsLimitNum),
          paramsLimitNumAuto: parseInt(this.bonus3.paramsLimitNumAuto),
          paramsGradeBuyTime0: this.bonus3.paramsGradeBuyTime0,
          paramsGradeBuyTime1: this.bonus3.paramsGradeBuyTime1,
          paramsGradeBuyTime2: this.bonus3.paramsGradeBuyTime2,
          paramsGradeBuyTime3: this.bonus3.paramsGradeBuyTime3
        }),
        exa_cond: JSON.stringify({
          balanceExpectNum: parseInt(this.bonus3Ex.balanceExpectNum),
          lastExpectNum: parseInt(this.bonus3Ex.lastExpectNum)
        }),
        status: parseInt(this.bonus3Status)
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this3.$alert('保存成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    clickSave4: function clickSave4() {
      var _this4 = this;

      configBonusHandle({
        id: this.id4,
        conditions: JSON.stringify(this.bonus4)
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _this4.$alert('保存成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    clickSave5: function clickSave5() {
      var _this5 = this;

      for (var index in this.bonus5) {
        var item = this.bonus5[index];
        item["pov"] = parseFloat(item["pov"]);
        item["rate"] = parseFloat(item["rate"]);
      }

      configBonusHandle({
        id: this.id5,
        conditions: JSON.stringify(this.bonus5),
        exa_cond: JSON.stringify({
          balanceExpectNum: parseInt(this.bonus5Ex.balanceExpectNum),
          lastExpectNum: parseInt(this.bonus5Ex.lastExpectNum)
        }),
        status: parseInt(this.bonus5Status)
      }).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _this5.$alert('保存成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    clickDeleteBonus1: function clickDeleteBonus1(index) {
      this.bonus1.splice(index, 1);
      var tmp = this.$tempModel.deepCopy(this.bonus1);

      for (var i in tmp) {
        tmp[i].label = parseInt(i) + 1;
      }

      this.bonus1 = tmp;
    },
    clickDeleteBonus4: function clickDeleteBonus4(index) {
      this.bonus1.splice(index, 1);
      var tmp = this.$tempModel.deepCopy(this.bonus1);

      for (var i in tmp) {
        tmp[i].label = parseInt(i) + 1;
      }

      this.bonus1 = tmp;
    },
    clickAddBonus1: function clickAddBonus1() {
      this.bonus4.push({
        layer: this.bonus4.length + 1,
        bonus: 30,
        grade3: 0
      });
    },
    clickAddBonus4: function clickAddBonus4() {
      this.bonus4.push({
        layer: this.bonus4.length + 1,
        bonus: 0.1
      });
    },
    clickGiftMake: function clickGiftMake() {
      var _this6 = this;

      refreshGift({}).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                _this6.$alert('刷新成功', '提示', {
                  confirmButtonText: '确定'
                });

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    }
  }
};